/*-----------------------------------------------------------------------------------
Thirdverse App
About: Thirdverse App
Author: JayJay
Version: 1.0
Built with Blocs
-----------------------------------------------------------------------------------*/
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page-container {
  overflow: hidden;
} /* Prevents unwanted scroll space when scroll FX used. */
a,
button {
  transition: background 0.3s ease-in-out;
  outline: none !important;
} /* Prevent blue selection glow on Chrome and Safari */
a:hover {
  text-decoration: none;
  cursor: pointer;
}
.scroll-fx-lock-init {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
} /* ScrollFX Lock */
.blocs-grid-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
} /* CSS Grid */
nav .dropdown-menu .nav-link {
  color: rgba(0, 0, 0, 0.6) !important;
} /* Maintain Downdown Menu Link Color in Navigation */
[data-bs-theme="dark"] nav .dropdown-menu .nav-link {
  color: var(--bs-dropdown-link-color) !important;
} /* Maintain Downdown Menu Link Color in Navigation in Darkmode */

/* Preloader */

.page-preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100000;
  background: #ffffff url("../public/images/pageload-spinner.gif") no-repeat
    center center;
  animation-name: preloader-fade;
  animation-delay: 2s;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.preloader-complete {
  animation-delay: 0.1s;
}
@keyframes preloader-fade {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* = Blocs
-------------------------------------------------------------- */

.bloc {
  width: 100%;
  clear: both;
  background: 50% 50% no-repeat;
  padding: 0 20px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
}
.bloc .container {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

/* Sizes */

.bloc-lg {
  padding: 100px 20px;
}
.bloc-md {
  padding: 50px 20px;
}

/* Dark theme */

.d-bloc {
  color: rgba(255, 255, 255, 0.7);
}
.d-bloc button:hover {
  color: rgba(255, 255, 255, 0.9);
}
.d-bloc .icon-round,
.d-bloc .icon-square,
.d-bloc .icon-rounded,
.d-bloc .icon-semi-rounded-a,
.d-bloc .icon-semi-rounded-b {
  border-color: rgba(255, 255, 255, 0.9);
}
.d-bloc .divider-h span {
  border-color: rgba(255, 255, 255, 0.2);
}
.d-bloc .a-btn,
.d-bloc .navbar a,
.d-bloc a .icon-sm,
.d-bloc a .icon-md,
.d-bloc a .icon-lg,
.d-bloc a .icon-xl,
.d-bloc h1 a,
.d-bloc h2 a,
.d-bloc h3 a,
.d-bloc h4 a,
.d-bloc h5 a,
.d-bloc h6 a,
.d-bloc p a {
  color: rgba(255, 255, 255, 0.6);
}
.d-bloc .a-btn:hover,
.d-bloc .navbar a:hover,
.d-bloc a:hover .icon-sm,
.d-bloc a:hover .icon-md,
.d-bloc a:hover .icon-lg,
.d-bloc a:hover .icon-xl,
.d-bloc h1 a:hover,
.d-bloc h2 a:hover,
.d-bloc h3 a:hover,
.d-bloc h4 a:hover,
.d-bloc h5 a:hover,
.d-bloc h6 a:hover,
.d-bloc p a:hover {
  color: rgba(255, 255, 255, 1);
}
.d-bloc .navbar-toggle .icon-bar {
  background: rgba(255, 255, 255, 1);
}
.d-bloc .btn-wire,
.d-bloc .btn-wire:hover {
  color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}
.d-bloc .card {
  color: rgba(0, 0, 0, 0.5);
}
.d-bloc .card button:hover {
  color: rgba(0, 0, 0, 0.7);
}
.d-bloc .card icon {
  border-color: rgba(0, 0, 0, 0.7);
}
.d-bloc .card .divider-h span {
  border-color: rgba(0, 0, 0, 0.1);
}
.d-bloc .card .a-btn {
  color: rgba(0, 0, 0, 0.6);
}
.d-bloc .card .a-btn:hover {
  color: rgba(0, 0, 0, 1);
}
.d-bloc .card .btn-wire,
.d-bloc .card .btn-wire:hover {
  color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.3);
}

/* Light theme */

.d-bloc .card,
.l-bloc {
  color: rgba(0, 0, 0, 0.5);
}
.d-bloc .card button:hover,
.l-bloc button:hover {
  color: rgba(0, 0, 0, 0.7);
}
.l-bloc .icon-round,
.l-bloc .icon-square,
.l-bloc .icon-rounded,
.l-bloc .icon-semi-rounded-a,
.l-bloc .icon-semi-rounded-b {
  border-color: rgba(0, 0, 0, 0.7);
}
.d-bloc .card .divider-h span,
.l-bloc .divider-h span {
  border-color: rgba(0, 0, 0, 0.1);
}
.d-bloc .card .a-btn,
.l-bloc .a-btn,
.l-bloc .navbar a,
.l-bloc a .icon-sm,
.l-bloc a .icon-md,
.l-bloc a .icon-lg,
.l-bloc a .icon-xl,
.l-bloc h1 a,
.l-bloc h2 a,
.l-bloc h3 a,
.l-bloc h4 a,
.l-bloc h5 a,
.l-bloc h6 a,
.l-bloc p a {
  color: rgba(0, 0, 0, 0.6);
}
.d-bloc .card .a-btn:hover,
.l-bloc .a-btn:hover,
.l-bloc .navbar a:hover,
.l-bloc a:hover .icon-sm,
.l-bloc a:hover .icon-md,
.l-bloc a:hover .icon-lg,
.l-bloc a:hover .icon-xl,
.l-bloc h1 a:hover,
.l-bloc h2 a:hover,
.l-bloc h3 a:hover,
.l-bloc h4 a:hover,
.l-bloc h5 a:hover,
.l-bloc h6 a:hover,
.l-bloc p a:hover {
  color: rgba(0, 0, 0, 1);
}
.l-bloc .navbar-toggle .icon-bar {
  color: rgba(0, 0, 0, 0.6);
}
.d-bloc .card .btn-wire,
.d-bloc .card .btn-wire:hover,
.l-bloc .btn-wire,
.l-bloc .btn-wire:hover {
  color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.3);
}

/* = NavBar
-------------------------------------------------------------- */

/* Navbar Icon */
.svg-menu-icon {
  fill: none;
  stroke: rgba(0, 0, 0, 0.5);
  stroke-width: 2px;
  fill-rule: evenodd;
}
.navbar-dark .svg-menu-icon {
  stroke: rgba(255, 255, 255, 0.5);
}
.menu-icon-thin-bars {
  stroke-width: 1px;
}
.menu-icon-thick-bars {
  stroke-width: 5px;
}
.menu-icon-rounded-bars {
  stroke-width: 3px;
  stroke-linecap: round;
}
.menu-icon-filled {
  fill: rgba(0, 0, 0, 0.5);
  stroke-width: 0px;
}
.navbar-dark .menu-icon-filled {
  fill: rgba(255, 255, 255, 0.5);
}
.navbar-toggler-icon {
  background: none !important;
  pointer-events: none;
  width: 33px;
  height: 33px;
}
/* Handle Multi Level Navigation */
.dropdown-menu .dropdown-menu {
  border: none;
}
@media (min-width: 576px) {
  .navbar-expand-sm .dropdown-menu .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .navbar-expand-sm .dropdown-menu .submenu-left {
    right: 100%;
    left: auto;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .dropdown-menu .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .navbar-expand-md .dropdown-menu .submenu-left {
    right: 100%;
    left: auto;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .navbar-expand-lg .dropdown-menu .submenu-left {
    right: 100%;
    left: auto;
  }
}
/* = Bric adjustment margins
-------------------------------------------------------------- */

.mg-clear {
  margin: 0;
}

/* = Buttons
-------------------------------------------------------------- */

.btn-d,
.btn-d:hover,
.btn-d:focus {
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}

/* Prevent ugly blue glow on chrome and safari */
button {
  outline: none !important;
}

.btn-rd {
  border-radius: 40px;
}
.a-block {
  width: 100%;
  text-align: left;
  display: inline-block;
}
.text-center .a-block {
  text-align: center;
}

/* = Cards
-------------------------------------------------------------- */
.card-sq,
.card-sq .card-header,
.card-sq .card-footer {
  border-radius: 0;
}
.card-rd {
  border-radius: 30px;
}
.card-rd .card-header {
  border-radius: 29px 29px 0 0;
}
.card-rd .card-footer {
  border-radius: 0 0 29px 29px;
}
/* = Masonary
-------------------------------------------------------------- */
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* = Forms
-------------------------------------------------------------- */

.form-control {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

/* = Custom Styling
-------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
.btn,
a {
  font-family: "Outfit";
  font-weight: 500;
}
.container {
  max-width: 1140px;
}
p {
  font-family: "Nunito Sans";
  font-weight: 300;
  color: var(--swatch-var-3647) !important;
}
.h2-style {
  font-family: "Outfit";
  font-weight: 700;
  color: #ffffff !important;
  font-size: 4em;
}
.navbar-brand img {
  height: 20px;
}
.nav-logo {
  margin-top: -8px;
}
.referral-logo {
  box-shadow: 0px -0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 20px 20px;
}
.nav-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}
.link-style {
  font-weight: bold;
}
.header-p {
  color: #ffffff !important;
}
.footer-link {
  text-decoration: none;
  text-transform: none;
  color: var(--swatch-var-4271) !important;
}
.text-span-style {
  font-weight: bold;
}
.button-unlinkaccount {
  color: var(--swatch-var-4271) !important;
  background-color: var(--swatch-var-3647);
}
button {
  background-color: var(--swatch-var-521);
}
button:hover {
  color: var(--swatch-var-5865) !important;
}
h3 {
  color: var(--swatch-var-7396) !important;
}
.text-span-bloc-2-style {
  font-weight: bold;
}
.username-tag {
  font-size: 0.9em;
}
.radio-button-game-select {
  color: var(--swatch-var-3647) !important;
}
.button-redeem {
  background-color: var(--swatch-var-521);
}
.button-redeem:visited {
  background-color: var(--swatch-var-521);
}
.button-redeem:focus {
  background-color: var(--swatch-var-521);
}
.button-redeem:active {
  background-color: var(--swatch-var-521);
}
.button-redeem:hover {
  background-color: var(--swatch-var-2869);
}

/* = Colour
-------------------------------------------------------------- */

/* Swatch Variables */
:root {
  --swatch-var-7396: rgba(36, 169, 223, 1);

  --swatch-var-3647: rgba(83, 97, 121, 1);

  --swatch-var-521: rgba(30, 32, 52, 1);

  --swatch-var-2869: rgba(0, 0, 0, 1);

  --swatch-var-4271: rgba(255, 255, 255, 1);

  --swatch-var-3838: rgba(151, 166, 203, 1);

  --swatch-var-3968: #edf1f9;

  --swatch-var-5865: rgba(43, 199, 157, 1);
}

/* Background colour styles */

.bgc-3968 {
  background-color: var(--swatch-var-3968);
}
.bgc-521 {
  background-color: var(--swatch-var-521);
}

/* Text colour styles */

.tc-3647 {
  color: var(--swatch-var-3647) !important;
}
.tc-7396 {
  color: var(--swatch-var-7396) !important;
}
.tc-4271 {
  color: var(--swatch-var-4271) !important;
}

/* Button colour styles */

.btn-c-521 {
  background: var(--swatch-var-521);
  color: #ffffff !important;
}
.btn-c-521:hover {
  background: #000001 !important;
  color: #ffffff !important;
}
.btn-c-3647 {
  background: var(--swatch-var-3647);
  color: #ffffff !important;
}
.btn-c-3647:hover {
  background: #202e46 !important;
  color: #ffffff !important;
}

/* Link colour styles */

.ltc-3647 {
  color: var(--swatch-var-3647) !important;
}
.ltc-3647:hover {
  color: #202e46 !important;
}

/* Bloc image backgrounds */

.bg-background-1 {
  background-image: url("../public/images/background-1.svg");
}

/* = Bloc Padding Multi Breakpoint
-------------------------------------------------------------- */

@media (min-width: 576px) {
  .bloc-xxl-sm {
    padding: 200px 20px;
  }
  .bloc-xl-sm {
    padding: 150px 20px;
  }
  .bloc-lg-sm {
    padding: 100px 20px;
  }
  .bloc-md-sm {
    padding: 50px 20px;
  }
  .bloc-sm-sm {
    padding: 20px;
  }
  .bloc-no-padding-sm {
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .bloc-xxl-md {
    padding: 200px 20px;
  }
  .bloc-xl-md {
    padding: 150px 20px;
  }
  .bloc-lg-md {
    padding: 100px 20px;
  }
  .bloc-md-md {
    padding: 50px 20px;
  }
  .bloc-sm-md {
    padding: 20px 20px;
  }
  .bloc-no-padding-md {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .bloc-xxl-lg {
    padding: 200px 20px;
  }
  .bloc-xl-lg {
    padding: 150px 20px;
  }
  .bloc-lg-lg {
    padding: 100px 20px;
  }
  .bloc-md-lg {
    padding: 50px 20px;
  }
  .bloc-sm-lg {
    padding: 20px;
  }
  .bloc-no-padding-lg {
    padding: 0 20px;
  }
}

/* = Mobile adjustments 
-------------------------------------------------------------- */
@media (max-width: 1024px) {
  .bloc.full-width-bloc,
  .bloc-tile-2.full-width-bloc .container,
  .bloc-tile-3.full-width-bloc .container,
  .bloc-tile-4.full-width-bloc .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .container {
    width: 100%;
  }
  .bloc {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
  } /* iPhone X Notch Support*/
  .bloc-group,
  .bloc-group .bloc {
    display: block;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .bloc-tile-2 .container,
  .bloc-tile-3 .container,
  .bloc-tile-4 .container {
    padding-left: 0;
    padding-right: 0;
  }
  .btn-dwn {
    display: none;
  }
  .voffset {
    margin-top: 5px;
  }
  .voffset-md {
    margin-top: 20px;
  }
  .voffset-lg {
    margin-top: 30px;
  }
  form {
    padding: 5px;
  }
  .close-lightbox {
    display: inline-block;
  }
  .blocsapp-device-iphone5 {
    background-size: 216px 425px;
    padding-top: 60px;
    width: 216px;
    height: 425px;
  }
  .blocsapp-device-iphone5 img {
    width: 180px;
    height: 320px;
  }
}

@media (max-width: 991px) {
  .h2-style {
    font-size: 3em;
  }
}

@media (max-width: 767px) {
  .h2-style {
    font-size: 2.5em;
  }
  .menu-icon-stroke {
    stroke: var(--swatch-var-3647) !important;
    fill: var(--swatch-var-3647) !important;
  }
}

@media (max-width: 575px) {
  .h2-style {
    font-size: 2.5em;
  }
  .menu-icon-stroke {
    stroke: var(--swatch-var-3647) !important;
    fill: var(--swatch-var-3647) !important;
  }
  .nav-padding {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .referral-logo {
    border-radius: 10px 10px 10px 10px;
  }
  .footer-link {
    text-align: center;
  }
  .button-linkaccount {
    line-height: 1.1em;
  }
  .username-tag {
    line-height: 1.2em;
  }
}

/* [Ryuta Yoshi] Adjusted button design inside the modal to avoid clashes with the overall design */
.modal button.close {
  background-color: transparent;
  border: none;
}

.modal button.close:hover {
  color: inherit !important;
}

.modal .modal-footer button:hover {
  color: #ffffff !important;
}

.modal .modal-footer button.btn-danger:hover {
  background-color: var(--bs-btn-bg);
}
